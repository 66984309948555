import { Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { complianceGuard } from './auth/compliance.guard';
import { AccountsComponent } from './accounts/accounts.component';
import { AccountDetailsComponent } from './accounts/account-details.component';
import { AppUnavailableComponent } from './core/app-unavailable.component';
import { LandingComponent } from './core/landing.component';
import { UsersComponent } from './users/users.component';
import { HomeComponent } from './home.component';
import { UserDocumentsComponent } from './users/user-documents.component';
import { MessagesComponent } from './users/messages/messages.component';
import { MotdsComponent } from './motds/motds.component';

export const routes: Routes = [
  {
    path: 'accounts',
    canActivate: [MsalGuard, complianceGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: AccountsComponent,
        canActivate: [MsalGuard, complianceGuard]
      },
      { path: 'details/:id', component: AccountDetailsComponent, canActivate: [MsalGuard, complianceGuard] }
    ]
  },
  { path: 'home', component: HomeComponent },
  {
    path: 'motds',
    canActivate: [MsalGuard, complianceGuard],
    component: MotdsComponent
  },
  {
    path: 'users',
    canActivate: [MsalGuard, complianceGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: UsersComponent,
        canActivate: [MsalGuard, complianceGuard]
      },
      { path: ':type', component: UsersComponent, canActivate: [MsalGuard, complianceGuard] },
      { path: ':type/:id/documents', component: UserDocumentsComponent, canActivate: [MsalGuard, complianceGuard] },
      { path: ':type/:id/messages', component: MessagesComponent, canActivate: [MsalGuard, complianceGuard] }
    ]
  },
  { path: 'unavailable', component: AppUnavailableComponent },
  { path: '', pathMatch: 'full', component: LandingComponent }
];
