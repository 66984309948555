import moment from 'moment';
import { CACHE_DURATION_HOURS, CIGP_COLORS, ID_PREFIX_CIGP } from './constants';
import { MatDialog } from '@angular/material/dialog';
import { ApiError } from './api-error.model';
import { ApiErrorDialogComponent } from './api-error-dialog.component';
import { BasicUser } from './basic-user.model';

export function showErrorDialog(apiErrorDialog: MatDialog, error: ApiError): void {
  apiErrorDialog.open(ApiErrorDialogComponent, {
    width: '800px',
    data: error
  });
}

export function randomCigpColor(): string {
  return CIGP_COLORS[Math.floor(Math.random() * CIGP_COLORS.length)];
}

export function isNotInCacheOrIsStale(
  cacheSetTimestamp: Date | string | null | undefined,
  duration = CACHE_DURATION_HOURS,
  durationUnit: moment.unitOfTime.Diff = 'hours'
): boolean {
  return !cacheSetTimestamp || moment().diff(moment(cacheSetTimestamp), durationUnit) > duration;
}

export function computePrefixedEmailAddress(user: BasicUser): string {
  return `${user.id.startsWith(ID_PREFIX_CIGP) ? ID_PREFIX_CIGP : ''}${user.email}`;
}
